<template>
  <div class="container">
    <a class="btn__back" @click="$router.push('/signup/card-details')">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 20C15.5141 20 20 15.5141 20 10C20 4.48586 15.5141 0 10 0C4.48586 0 0 4.48586 0 10C0 15.5141 4.48586 20 10 20ZM6.21121 9.68795L11.4263 4.47283C11.5115 4.38763 11.6228 4.34591 11.734 4.34591C11.8453 4.34591 11.9565 4.38849 12.0417 4.47283C12.2112 4.64232 12.2112 4.91786 12.0417 5.08735L7.13429 9.99479L12.0409 14.9031C12.2104 15.0726 12.2104 15.3481 12.0409 15.5176C11.8714 15.6871 11.5958 15.6871 11.4263 15.5176L6.21121 10.3025C6.04172 10.133 6.04172 9.85744 6.21121 9.68795Z"
          fill="#DB353A"
        />
      </svg>
      <span>Back</span>
    </a>

    <h2 class="leading">Profile Details</h2>

    <form class="form" @submit.prevent="submitForm" novalidate="true">
      <div class="form__group">
        <label for="username">
          <input
            type="text"
            id="username"
            name="username"
            placeholder="Username"
            class="form__input"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            v-model.lazy="$v.form.username.$model"
            :class="{ 'is-invalid': submitted && $v.form.username.$error }"
        /></label>
        <small class="error" v-if="submitted && !$v.form.username.required"
          >Username is required</small
        >
      </div>
      <div class="form__group">
        <label for="dob">
          <input
            type="text"
            onfocus="(this.type='date')"
            id="dob"
            name="dob"
            placeholder="Date of Birth"
            class="form__input"
            v-model.lazy="$v.form.dob.$model"
            :class="{ 'is-invalid': submitted && $v.form.dob.$error }"
        /></label>
        <small class="error" v-if="submitted && !$v.form.dob.required"
          >Date of birth is required</small
        >
      </div>
      <div class="form__group">
        <label for="password" class="form__label">
          <input
            id="password"
            :type="passwordFieldType"
            name="password"
            class="form__input"
            placeholder="Password"
            v-model="$v.form.password.$model"
            :class="{ 'is-invalid': submitted && $v.form.password.$error }"
        /></label>
        <span @click="switchVisibility" class="form__password">{{
          passwordFieldType === "password" ? "Show" : "Hide"
        }}</span>
        <span v-if="submitted && $v.form.password.$error" class="error">
          <small class="error" v-if="!$v.form.password.required"
            >Password is required</small
          >
          <br />
          <small class="error" v-if="!$v.form.password.strongPassword"
            >Strong passwords need to have a letter, a number, a special character, and be
            more than 8 characters long.</small
          >
        </span>
      </div>
      <div class="form__group">
        <label for="passwordConfirm" class="form__label">
          <input
            id="passwordConfirm"
            :type="passwordFieldTypeTwo"
            name="passwordConfirm"
            class="form__input"
            placeholder="Confirm Password"
            v-model="$v.form.passwordConfirm.$model"
            :class="{
              'is-invalid': submitted && $v.form.passwordConfirm.$error,
            }"
        /></label>
        <span @click="switchVisibilityTwo" class="form__password">{{
          passwordFieldTypeTwo === "password" ? "Show" : "Hide"
        }}</span>
        <span v-if="submitted && $v.form.passwordConfirm.$error" class="error">
          <small class="error" v-if="!$v.form.passwordConfirm.required"
            >Confirm password is required</small
          >
          <br />
          <small class="error" v-if="!$v.form.passwordConfirm.sameAsPassword"
            >Passwords do not match.</small
          >
        </span>
      </div>
      <div class="form__group">
        <label for="pin">
          <input
            id="pin"
            :type="passwordFieldTypeThree"
            name="pin"
            class="form__input"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            placeholder="Transaction Pin"
            v-model="$v.form.pin.$model"
            :class="{ 'is-invalid': submitted && $v.form.pin.$error }"
        /></label>
        <span @click="switchVisibilityThree" class="form__password">{{
          passwordFieldTypeThree === "password" ? "Show" : "Hide"
        }}</span>
        <span v-if="submitted && $v.form.pin.$error" class="error">
          <small class="error" v-if="!$v.form.pin.required">Pin is required</small>
          <!-- <br /> -->
          <small class="error" v-if="!$v.form.pin.numeric"
            >Needs to be numeric only.</small
          >
          <!-- <br /> -->
          <small class="error" v-if="!$v.form.pin.maxLength || !$v.form.pin.minLength"
            >Pin must be 4 numbers.</small
          >
        </span>
      </div>
      <div class="form__group">
        <label for="pinConfirm">
          <input
            id="pinConfirm"
            :type="passwordFieldTypeFour"
            name="pinConfirm"
            class="form__input"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            placeholder="Confirm Transaction Pin"
            v-model="$v.form.pinConfirm.$model"
            :class="{
              'is-invalid': submitted && $v.form.pinConfirm.$error,
            }"
        /></label>
        <span @click="switchVisibilityFour" class="form__password">{{
          passwordFieldTypeFour === "password" ? "Show" : "Hide"
        }}</span>
        <span v-if="submitted && $v.form.pinConfirm.$error" class="error">
          <small class="error" v-if="!$v.form.pinConfirm.required"
            >Confirm pin is required</small
          >
          <!-- <br /> -->
          <small class="error" v-if="!$v.form.pinConfirm.sameAsPin"
            >Pins do not match.</small
          >
          <!-- <br /> -->
          <small class="error" v-if="!$v.form.pinConfirm.numeric"
            >Needs to be numeric only.</small
          >
          <!-- <br /> -->
          <small class="error" v-if="!$v.form.pin.maxLength || !$v.form.pin.minLength"
            >Pin must be 4 numbers.</small
          >
        </span>
      </div>
      <div class="form__group">
        <label for="referralCode">
          <input
            type="text"
            id="referralCode"
            name="referralCode"
            placeholder="Referral Code"
            class="form__input"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            v-model.lazy="form.referralCode"
        /></label>
      </div>
      <button type="submit" class="btn btn__red btn__group">
        <loading v-if="loading" />
        <span>Proceed</span>
      </button>
    </form>
  </div>
</template>

<script>
import api from "@/api/api.js";
import {
  required,
  sameAs,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { mapState } from "vuex";
export default {
  computed: mapState(["userDetails", "cardDetail"]),
  data() {
    return {
      passwordFieldType: "password",
      passwordFieldTypeTwo: "password",
      passwordFieldTypeThree: "password",
      passwordFieldTypeFour: "password",
      loading: false,
      submitted: false,
      form: {
        username: "",
        dob: "",
        password: "",
        passwordConfirm: "",
        pin: "",
        pinConfirm: "",
        referralCode: "",
      },
    };
  },
  validations: {
    form: {
      username: { required },
      dob: { required },
      password: {
        required,
        strongPassword(password) {
          return (
            /[a-z]/.test(password) &&
            /[0-9]/.test(password) &&
            /\W|_/.test(password) &&
            password.length >= 8
          );
        },
      },
      passwordConfirm: { required, sameAsPassword: sameAs("password") },
      pin: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
      pinConfirm: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4),
        sameAsPin: sameAs("pin"),
      },
    },
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    switchVisibilityTwo() {
      this.passwordFieldTypeTwo =
        this.passwordFieldTypeTwo === "password" ? "text" : "password";
    },
    switchVisibilityThree() {
      this.passwordFieldTypeThree =
        this.passwordFieldTypeThree === "password" ? "text" : "password";
    },
    switchVisibilityFour() {
      this.passwordFieldTypeFour =
        this.passwordFieldTypeFour === "password" ? "text" : "password";
    },
    submitForm() {
      this.submitted = true;

      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      let formData = {
        nuban: this.userDetails.nuban,
        bvn: this.userDetails.bvn,
        username: this.form.username,
        password: this.form.password,
        transactionpin: this.form.pin,
        dob: this.form.dob,
        referralcode: this.form.referralCode,
      };

      let payload = { ...this.cardDetail, ...formData };

      this.loading = true;

      api
        .handleOnboardSterlingCustomers(payload)
        .then((response) => {
          this.loading = false;
          let responseStatus = response.Status;
          let responseMessage = response.Message;
          if (responseStatus) {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "success",
              duration: 10000,
            });
            let userDetails = response.Data;
            this.$store.commit("setUser", userDetails);
            this.$router.push("/signup/security-questions");
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
